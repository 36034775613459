.homepage {
    display: flex;
    justify-content: center;
    align-items: center;
  
    .container {
      width: 90%;
  
      
        h2 {
          display: flex;
          justify-content: center;
        }

        button{
          display: flex;
          justify-content: center;
          width: 100%;
          background-color: lightgrey;
          padding: 0px 50px;
        }
      
  
      .result {
        display: flex;
        justify-content: center;
      }

     
    }
  }
  